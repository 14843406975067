// Import SweetAlert2
import Swal from 'sweetalert2';
window.Swal = Swal;

import './siriuspush';

window.addEventListener('load', function() {
    SiriusPush.init(appConfig.notifications);

    SiriusPush.on('onPermissionDefault', function() {
        if (localStorage.getItem('SP_widgetClosed') == '-1') {
            localStorage.removeItem('SP_widgetClosed');
        }
    });

    SiriusPush.on('onReady', function() {
        if (SiriusPush.displayWidget()) {
            fireSiriusPushWidget(60 * 60 * 24 * 7);
        } else {
            localStorage.setItem('SP_widgetClosed', '-1');
        }
    });
});

function fireSiriusPushWidget(ttl) {
    let closed_at = localStorage.getItem('SP_widgetClosed');
    let now = Math.floor(Date.now() / 1000);

    if (closed_at == null || (closed_at > 0 && (now - closed_at) > ttl)) {
        Swal.fire({
            position: 'center',
            html: '<h3 class="push-message"><i class="fas fa-bell"></i><br>اشترك في خدمة الإشعارات من دنيا الوطن ليصلك آخر الأخبار فور وقوعها</h3>',
            showConfirmButton: true,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonColor: '#dd0000',
            confirmButtonText: 'اشترك',
            cancelButtonText: 'ذكرني لاحقا',
            customClass: {
                popup: 'swal2-popup push-popup',
                actions: 'swal2-actions push-actions'
            }
        }).then((result) => {
            if (result.value) {
                SiriusPush.subscribe();
                localStorage.setItem('SP_widgetClosed', '-1');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                localStorage.setItem('SP_widgetClosed', now);
            }
        });
    }
}

'use strict';

// Permissions
export const PERMISSION_DENIED = 'denied';
export const PERMISSION_GRANTED = 'granted';
export const PERMISSION_DEFAULT = 'default';

// Constants
export const KEY_DEVICE_ID = 'SP_deviceId';
export const KEY_DEVICE_STATUS = 'SP_deviceStatus';
export const DEVICE_STATUS_REGISTERED = 'registered';
export const DEVICE_STATUS_UNREGISTERED = 'unregistered';
export const DEVICE_STATUS_USER_UNSUBSCRIBED = 'userUnsubscribed';
export const EVENT_SUBSCRIBED_TIME = 'SP_eventSubscribedTime';
export const EVENT_UNSUBSCRIBED_TIME = 'SP_eventUnsubscribedTime';
export const SUBSCRIPTION_TTL = 1209600; // 2 Weeks

// API endpoints
export const API_ENDPOINT_SUBSCRIBE = 'https://api.alwatanvoice.com/v1/push/subscribe';
export const API_ENDPOINT_UNSUBSCRIBE = 'https://api.alwatanvoice.com/v1/push/unsubscribe';
export const API_ENDPOINT_RESUBSCRIBE = 'https://api.alwatanvoice.com/v1/push/resubscribe';
export const API_ENDPOINT_EVENT = 'https://api.alwatanvoice.com/v1/push/event';
export const API_ENDPOINT_MESSAGE = 'https://api.alwatanvoice.com/v1/push/message';
export const API_ENDPOINT_DEVICE = 'https://api.alwatanvoice.com/v1/push/device';

// FCM
export const FCM_ENDPOINT_SUBSCRIBE = 'https://fcm.googleapis.com/fcm/connect/subscribe';

// Available events
export const EVENT_ON_READY = 'onReady';
export const EVENT_ON_SUBSCRIBED = 'onSubscribed';
export const EVENT_ON_UNSUBSCRIBED = 'onUnsubscribed';
export const EVENT_ON_PERMISSION_DEFAULT = 'onPermissionDefault';
export const EVENT_ON_PERMISSION_DENIED = 'onPermissionDenied';
export const EVENT_ON_PERMISSION_GRANTED = 'onPermissionGranted';

// Tracked events to be cleared on cleanup
export const TRACKED_EVENTS = [
    'SP_eventResubscribe',
    'SP_eventSubscribedTime',
    'SP_eventUnsubscribedTime',
    'SP_eventUserUnsubscribe',
    'SP_eventPermissionDenied',
    'SP_eventPermissionDeniedOnRequest',
    'SP_eventUnsubscribeOnPermissionDefault',
    'SP_eventUnsubscribeOnPermissionDenied'
];

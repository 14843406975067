'use strict';

import { upperCaseWords } from './helpers';
import * as CONSTANTS from './constants';
import PushAPI from './api';
import PushEvents from './events';
import PushLogger from './logger';

// Defualt options
const defaults = {
    fcmSubscribe: false,
    fcmSenderId: '',
    serviceWorker: '/serviceworker.js',
    scope: '/',
    vapidKey: '',
    autoSubscribe: false,
    logLevel: 'error'
};

export default class PushClient {
    constructor(config) {
        // Merge config with default values
        this._config = { ...defaults, ...config };

        this._api = new PushAPI;
        this._logger = new PushLogger;
        this._events = new PushEvents;
    }

    async init() {
        await this.registerServiceWorker();

        const permission = this.getPermission();

        switch (permission) {
            case CONSTANTS.PERMISSION_GRANTED:
                // Check if it is needed to resubscribe the user
                if (await this.isNeedResubscribe()) {
                    this.trackEvent('resubscribe');

                    await this.resubscribe();
                }

                // emit event when permission is granted
                this._events.emit(CONSTANTS.EVENT_ON_PERMISSION_GRANTED);
                break;

            case CONSTANTS.PERMISSION_DEFAULT:
                await this.onPermissionDefault();
                break;

            case CONSTANTS.PERMISSION_DENIED:
                await this.onPermissionDenied();

                this.trackEvent('permissionDenied');
                break;
        }

        // emit event when PuchClient is finishing initialization
        this._events.emit(CONSTANTS.EVENT_ON_READY);
    }

    setConfig(config) {
        // Merge config with default values
        this._config = { ...defaults, ...config };
    }

    getEvents() {
        return this._events;
    }

    async onPermissionDefault() {
        // User cannot be registered when permission is default
        if (this.isDeviceRegistered()) {
            await this.unsubscribe(false);

            this.trackEvent('unsubscribeOnPermissionDefault');

            // Clear any data stored previously
            this.cleanup();
        }

        // Ask for permission if the auto subscribe is enabled
        if (this._config.autoSubscribe) {
            // In Firefox 72 and greater we must call this event
            // by a user interaction
            const browser = this.detectBrowser();

            if (browser.name == 'Firefox' && browser.version >= 72) {
                return;
            }

            await this.askSubscription();
        }

        // emit event when permission is default
        this._events.emit(CONSTANTS.EVENT_ON_PERMISSION_DEFAULT);
    }

    async onPermissionDenied() {
        // User cannot be registered when permission is denied
        if (this.isDeviceRegistered()) {
            await this.unsubscribe(false);

            this.trackEvent('unsubscribeOnPermissionDenied');

            // Clear any data stored previously
            this.cleanup();
        }

        // emit event when permission is denied
        this._events.emit(CONSTANTS.EVENT_ON_PERMISSION_DENIED);
    }

    async askSubscription() {
        const permission = await this.askPermission();

        if (permission == CONSTANTS.PERMISSION_GRANTED) {
            await this.subscribe();

            // emit event when permission is granted
            this._events.emit(CONSTANTS.EVENT_ON_PERMISSION_GRANTED);
        } else if (permission == CONSTANTS.PERMISSION_DENIED) {
            await this.onPermissionDenied();

            this.trackEvent('permissionDeniedOnRequest');
        }
    }

    async trackEvent(event) {
        let eventName = 'SP_event' + upperCaseWords(event);

        if (localStorage.getItem(eventName) != null) {
            return;
        }

        localStorage.setItem(eventName, true);

        await this._api.trackEvent(event);
    }

    cleanup(removeDeviceId = true) {
        // Remove basic items
        localStorage.removeItem(CONSTANTS.EVENT_SUBSCRIBED_TIME);
        localStorage.removeItem(CONSTANTS.EVENT_UNSUBSCRIBED_TIME);
        localStorage.removeItem(CONSTANTS.KEY_DEVICE_STATUS);

        if (removeDeviceId) {
            localStorage.removeItem(CONSTANTS.KEY_DEVICE_ID);
        }

        // Remove events
        CONSTANTS.TRACKED_EVENTS.forEach((event, index) => {
            localStorage.removeItem(event);
        });
    }

    isPushNotificationSupported() {
        // Currently, we do not support Safari
        let browser = this.detectBrowser();

        return 'serviceWorker' in navigator && 'PushManager' in window && browser.name != 'Safari';
    }

    async registerServiceWorker() {
        if (this.isPushNotificationSupported()) {
            navigator.serviceWorker.register(this._config.serviceWorker, {
                scope: this._config.scope
            }).then((registration) => {
                this._registration = registration;

                return registration;
            });
        } else {
            this._logger.warning('Push messaging is not supported.');
        }
    }

    async getServiceWorkerRegistration() {
        if (!this._registration) {
            await this.registerServiceWorker();

            this._registration = await navigator.serviceWorker.getRegistration();

            await this._registration.update();
        }

        return this._registration;
    }

    async askPermission() {
        return await Notification.requestPermission();
    }

    getPermission() {
        return Notification.permission;
    }

    isPermissionGranted() {
        return this.getPermission() === CONSTANTS.PERMISSION_GRANTED;
    }

    isPermissionDefault() {
        return this.getPermission() === CONSTANTS.PERMISSION_DEFAULT;
    }

    isPermissionDenied() {
        return this.getPermission() === CONSTANTS.PERMISSION_DENIED;
    }

    setDeviceStatus(status) {
        return localStorage.setItem(CONSTANTS.KEY_DEVICE_STATUS, status);
    }

    isDeviceRegistered() {
        return localStorage.getItem(CONSTANTS.KEY_DEVICE_STATUS) == CONSTANTS.DEVICE_STATUS_REGISTERED;
    }

    isDeviceUnregistered() {
        return localStorage.getItem(CONSTANTS.KEY_DEVICE_STATUS) == CONSTANTS.DEVICE_STATUS_UNREGISTERED;
    }

    isUserUnsubscribed() {
        return localStorage.getItem(CONSTANTS.KEY_DEVICE_STATUS) == CONSTANTS.DEVICE_STATUS_USER_UNSUBSCRIBED;
    }

    setEventTime(event) {
        return localStorage.setItem(event, Math.floor(Date.now() / 1000));
    }

    getEventTime(event) {
        let time = localStorage.getItem(event);

        return time == null ? 0 : time;
    }

    removeEventTime(event) {
        return localStorage.removeItem(event);
    }

    async isNeedResubscribe() {
        let currentTime = Math.floor(Date.now() / 1000);
        let subscribedTime = this.getEventTime(CONSTANTS.EVENT_SUBSCRIBED_TIME);
        let isExpired = (currentTime - subscribedTime > CONSTANTS.SUBSCRIPTION_TTL);

        let deviceRegistered = false;

        if (this.hasDeviceId()) {
            let deviceOnServer = await this._api.checkDevice(this.getDeviceId(false));

            if (deviceOnServer.exists) {
                deviceRegistered = true;
            }
        }

        let invalidPushSubscription = !(await this.getPushManagerSubscription());

        return this.isPermissionGranted() && !this.isUserUnsubscribed() && (!deviceRegistered || invalidPushSubscription || isExpired);
    }

    isSubscribed() {
        return this.isPermissionGranted() && this.isDeviceRegistered();
    }

    async subscribe(forceSubscribe = false) {
        if (!this.isPermissionGranted()) {
            this._logger.error('You must have permission granted before subscribe');
            return;
        }

        if (forceSubscribe) {
            await this.unsubscribe(false);
        }

        // Do not subscribe again if we already subscribed
        if (this.isSubscribed()) {
            return;
        }

        // create a new subscription
        const subscription = await this.pushManagerSubscribe();

        const _p256dn = subscription.getKey('p256dh');
        const _auth = subscription.getKey('auth');

        if (!_p256dn || !_auth) {
            throw new Error('Cannot get subscription keys');
        }

        const p256dh = btoa(String.fromCharCode.apply(String, new Uint8Array(_p256dn)));
        const auth = btoa(String.fromCharCode.apply(String, new Uint8Array(_auth)));

        const vapidKey = this.getVapidKey();

        // register device in fcm
        const { token, pushSet } = await this.fcmSubscribe({
            endpoint: subscription.endpoint,
            application_pub_key: vapidKey ? vapidKey : undefined,
            encryption_key: p256dh,
            encryption_auth: auth,
            authorized_entity: this._config.fcmSenderId
        });

        const deviceId = this.getDeviceId();
        const browser = this.detectBrowser();

        const tokens = {
            publicKey: p256dh,
            authToken: auth,
            pushToken: subscription.endpoint,
            fcmPushSet: pushSet,
            fcmToken: token,
            browserName: browser.name,
            browserVersion: browser.version,
            client: 'web',
            deviceId: deviceId
        };

        // send tokens to the server
        await this._api.subscribe(tokens);

        // Set device status to REGISTERED
        this.setDeviceStatus(CONSTANTS.DEVICE_STATUS_REGISTERED);

        // Set subscription time
        this.setEventTime(CONSTANTS.EVENT_SUBSCRIBED_TIME);

        // Remove unsubscription time if it is set
        this.removeEventTime(CONSTANTS.EVENT_UNSUBSCRIBED_TIME);

        // emit event when the user is subscribed
        this._events.emit(CONSTANTS.EVENT_ON_SUBSCRIBED);
    }

    async unsubscribe(userUnsubscribed = true) {
        // get device id
        const deviceId = this.getDeviceId(false);

        // remove device from the server
        if (deviceId) {
            await this._api.unsubscribe(deviceId);

            if (userUnsubscribed) {
                this.trackEvent('userUnsubscribe');
            }
        }

        // Get browser subscription
        const subscription = await this.getPushManagerSubscription();

        if (!subscription) {
            this.cleanup(false);

            return;
        }

        // Set device status to UNREGISTERED
        this.setDeviceStatus(CONSTANTS.DEVICE_STATUS_UNREGISTERED);

        // Set unsubscription time
        if (userUnsubscribed) {
            this.setDeviceStatus(CONSTANTS.DEVICE_STATUS_USER_UNSUBSCRIBED);

            this.setEventTime(CONSTANTS.EVENT_UNSUBSCRIBED_TIME);
        }

        // unsubscribe
        await subscription.unsubscribe();

        // emit event when the user is unsubscribed
        this._events.emit(CONSTANTS.EVENT_ON_UNSUBSCRIBED);
    }

    async resubscribe() {
        // remove the current subscription
        await this.removePushManagerSubscription();

        // clean up local storage
        this.cleanup(false);

        // subscribe again
        await this.subscribe();
    }

    async pushManagerSubscribe() {
        const registration = await this.getServiceWorkerRegistration();
        const applicationServerKey = this.getVapidKey(true);

        return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        });
    }

    async getPushManagerSubscription() {
        // get service worker registration
        const registration = await this.getServiceWorkerRegistration();

        // return current subscription
        return registration.pushManager.getSubscription();
    }

    async removePushManagerSubscription() {
        // remove the current subscription
        const subscription = await this.getPushManagerSubscription();

        if (subscription) {
            return await subscription.unsubscribe();
        }

        return false;
    }

    async fcmSubscribe(config) {
        if (!this._config.fcmSubscribe) {
            return { token: null, pushSet: null };
        }

        const response = await fetch(CONSTANTS.FCM_ENDPOINT_SUBSCRIBE, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
            },
            body: JSON.stringify(config)
        });

        if (response.status === 200) {
            return response.json();
        }

        throw new Error('Cannot register device in fcm. Response: ' + response);
    }

    getVapidKey(base64Encoded = false) {
        if (base64Encoded) {
            return this.base64UrlToUint8Array(this._config.vapidKey);
        }

        return this._config.vapidKey;
    }

    getDeviceId(generateIfNotExists = true) {
        let deviceId = localStorage.getItem(CONSTANTS.KEY_DEVICE_ID);

        if (deviceId == null && generateIfNotExists) {
            // generate a new unique id
            deviceId = this.newGUID();

            // store in the local storage
            localStorage.setItem(CONSTANTS.KEY_DEVICE_ID, deviceId);
        }

        return deviceId;
    }

    hasDeviceId() {
        return this.getDeviceId(false) != null;
    }

    newGUID() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    detectBrowser() {
        if (!this._browser) {
            var ua = navigator.userAgent,
                tem,
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return { name: 'IE', version: (tem[1] || '') };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null)
                M.splice(1, 1, tem[1]);
            this._browser = { name: M[0], version: M[1] };
        }

        return this._browser;
    }

    base64UrlToUint8Array(base64UrlData) {
        const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
        const base64 = (base64UrlData + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const buffer = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            buffer[i] = rawData.charCodeAt(i);
        }
        return buffer;
    }
}

'use strict';

import PushClient from './siriuspush/client';

let _pushService;
//let _onReadyEvent = [];

export class SiriusPush {
    static init(config) {
        try {
            if (_pushService) {
                console.warning('PushClient is already initialized!');

                return _pushService;
            }

            _pushService = new PushClient(config);

            if (!_pushService.isPushNotificationSupported()) {
                _pushService = null;

                console.warning('Push messaging is not supported.');

                return;
            }

            _pushService.init();
        } catch (error) {
            console.log(error);
        }
    }

    static getPushService() {
        if (!_pushService) {
            throw new Error('You must init PushClient before calling this method.');
        }

        return _pushService;
    }

    static subscribe() {
        try {
            if (!_pushService) {
                throw new Error('You must init PushClient before calling this method.');
            }

            if (_pushService.isSubscribed()) {
                return;
            }

            _pushService.askSubscription();
        } catch (error) {
            console.log(error);
        }
    }

    static isSubscribed() {
        try {
            if (!_pushService) {
                throw new Error('You must init PushClient before calling this method.');
            }

            return _pushService.isSubscribed();
        } catch (error) {
            console.log(error);
        }

        return false;
    }

    static isPermissionGranted() {
        try {
            if (!_pushService) {
                throw new Error('You must init PushClient before calling this method.');
            }

            return _pushService.isPermissionGranted();
        } catch (error) {
            console.log(error);
        }

        return false;
    }

    static isPermissionDefault() {
        try {
            if (!_pushService) {
                throw new Error('You must init PushClient before calling this method.');
            }

            return _pushService.isPermissionDefault();
        } catch (error) {
            console.log(error);
        }

        return false;
    }

    static isPermissionDenied() {
        try {
            if (!_pushService) {
                throw new Error('You must init PushClient before calling this method.');
            }

            return _pushService.isPermissionDenied();
        } catch (error) {
            console.log(error);
        }

        return false;
    }

    static unsubscribe() {
        try {
            if (!_pushService) {
                throw new Error('You must init PushClient before calling this method.');
            }

            if (!_pushService.isSubscribed()) {
                return;
            }

            _pushService.unsubscribe(true);
        } catch (error) {
            console.log(error);
        }

        return false;
    }

    static on(event, listener) {
        try {
            if (!_pushService) {
                throw new Error('You must init PushClient before calling this method.');
            }

            _pushService.getEvents().on(event, listener);
        } catch (error) {
            console.log(error);
        }
    }

    static displayWidget() {
        if (!_pushService) {
            throw new Error('You must init PushClient before calling this method.');
        }

        return !_pushService.isPermissionGranted() || !_pushService.isSubscribed();
    }
}

if (window) {
    window.SiriusPush = SiriusPush;
}

'use strict';

export default class PushLogger {
    constructor(logLevel) {
        this._logLevel = logLevel;
    }

    log(level, message) {
        if (level == this._logLevel) {
            console.log(message);
        }
    }

    error(message) {
        this.log('error', message);
    }

    warning(message) {
        this.log('warning', message);
    }

    info(message) {
        this.log('info', message);
    }

    debug(message) {
        this.log('debug', message);
    }
}

'use strict';

export default class PushEvents {
    constructor() {
        this.events = {};
    }

    _getEventListByName(event) {
        if (typeof this.events[event] === 'undefined') {
            this.events[event] = new Set();
        }

        return this.events[event];
    }

    on(event, fn) {
        this._getEventListByName(event).add(fn);
    }

    once(event, fn) {
        const self = this;

        const onceFn = function(...args) {
            self.removeListener(event, onceFn);
            fn.apply(self, args);
        };

        this.on(event, onceFn);
    }

    emit(event, ...args) {
        this._getEventListByName(event).forEach(function(fn) {
            fn.apply(this, args);
        }.bind(this));
    }

    removeListener(event, fn) {
        this._getEventListByName(event).delete(fn);
    }
}

'use strict';

import * as CONSTANTS from './constants';

export default class PushAPI {
    async request(url, body, method = 'POST') {
        let init = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        if (method == 'POST') {
            init.body = JSON.stringify(body);
        }

        const response = await fetch(url, init);

        if (response.status == 200) {
            return response.json();
        }

        return false;
    }

    async subscribe(tokens) {
        if (!CONSTANTS.API_ENDPOINT_SUBSCRIBE) {
            return;
        }

        return await this.request(CONSTANTS.API_ENDPOINT_SUBSCRIBE, tokens);
    }

    async resubscribe(tokens) {
        if (!CONSTANTS.API_ENDPOINT_RESUBSCRIBE) {
            return;
        }

        return await this.request(CONSTANTS.API_ENDPOINT_RESUBSCRIBE, tokens);
    }

    async unsubscribe(deviceId) {
        if (!CONSTANTS.API_ENDPOINT_UNSUBSCRIBE) {
            return;
        }

        return await this.request(CONSTANTS.API_ENDPOINT_UNSUBSCRIBE, { 'deviceId': deviceId });
    }

    async checkDevice(deviceId) {
        if (!CONSTANTS.API_ENDPOINT_DEVICE) {
            return;
        }

        return await this.request(CONSTANTS.API_ENDPOINT_DEVICE + '/' + deviceId, null, 'GET');
    }

    async trackEvent(event, data) {
        if (!CONSTANTS.API_ENDPOINT_EVENT) {
            return;
        }

        if (typeof data == 'undefined') {
            data = {};
        }

        return await this.request(CONSTANTS.API_ENDPOINT_EVENT, { 'event': event, 'data': data });
    }
}
